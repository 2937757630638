var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ion-card',{staticClass:"margin-death",staticStyle:{"overflow":"initial","padding":"0"}},[(_vm.mini)?_c('ion-row',[_c('common-patient-banner',{staticStyle:{"width":"100%"},domProps:{"patient":this.patientInterface,"searchPatient":this.patientConfigData,"show_nav_helper":false,"context_menu_content":this.contextMenuContentMini,"push_to_elation_loading":this.pushToElationLoading,"push_to_elation_success":this.pushToElationSuccess}})],1):_c('ion-row',{staticClass:"patient-banner-row"},[_c('div',{staticClass:"common-patient-banner-wrapper"},[_c('common-patient-banner',{attrs:{"patient_overview_enabled":_vm.isNavPatientOverview},domProps:{"patient":this.patientInterface,"searchPatient":this.patientConfigData,"dropdown_content":_vm.cPatient.patientDetails
            ? [
                { btnTitle: 'Patient Details', content: _vm.showPatientDetails(_vm.cPatient) },
                { btnTitle: 'Contact Info', content: _vm.showContactInfo(_vm.cPatient) },
                { btnTitle: 'Provider Info', content: _vm.showProviderInfo(_vm.cPatient) },
                { btnTitle: 'Cost / Utilization', content: _vm.showCostUtilizationInfo(_vm.cPatient) },
                { btnTitle: 'Payer / Medical Group', content: _vm.showPayerInfo(_vm.cPatient) },
                { btnTitle: 'Recent Measurements', content: _vm.showRecentMeasurement(_vm.cPatient) }
              ]
            : [
                { btnTitle: 'Member ID', content: _vm.showMemberId(_vm.cPatient) },
                { btnTitle: 'Organization', content: _vm.showOrganization(_vm.cPatient) },
                { btnTitle: 'Address', content: _vm.showAddress(_vm.cPatient) },
                { btnTitle: 'Email', content: _vm.showEmail(_vm.cPatient) },
                { btnTitle: 'Home Phone', content: _vm.showHomePhone(_vm.cPatient) },
                { btnTitle: 'SMS Phone', content: _vm.showSmsPhone(_vm.cPatient) }
              ],"context_menu_content":this.contextMenuContent,"push_to_elation_loading":this.pushToElationLoading,"push_to_elation_success":this.pushToElationSuccess,"show_nav_helper":_vm.$can(_vm.I.SEND_MESSAGE) || _vm.isNavPatientOverview,"nav_helper_enabled":_vm.cPatient.enrollmentStatus == 'approved',"nav_helper_function":_vm.navHelperFunction}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }