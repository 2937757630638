<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <IconPhone slot="start" class="icon-small" />
        <h5 slot="start">Initiate Patient Call</h5>
        <ion-button slot="end" mode="ios" @click="closeModal(null)" expand="full" color="primary" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <div class="prime-modal-content">
      <p>
        This will initiate a call from the system to the patient's phone number that you provide. Once the call has answered and confirmed, the call will be connected to the number you provide.
      </p>
    </div>
    <div class="call-content" v-if="!isCallInitiated">
      <div class="margin-lr-twenty prime-modal-patient-results-notifications" v-if="notifications.length">
        <div v-if="notifications.length > 0" :class="{ 'danger-well': isError }" class="margin-bottom-ten notifications-well">
          <div v-for="(notification, key) in notifications" :key="key">
            {{ notification }}
          </div>
        </div>
      </div>
      <div class="prime-modal-content">
        <h6>Patient's phone number:</h6>
        <input class="form-control margin-bottom-ten" type="text" placeholder="(xxx) xxx-xxxx" v-model="displayedPatientNumber" v-mask="'(999) 999-9999'" />
      </div>
      <div class="prime-modal-content">
        <h6>Provider's phone number:</h6>
        <input class="form-control margin-bottom-ten" type="text" placeholder="(xxx) xxx-xxxx" v-model="providerNumber" v-mask="'(999) 999-9999'" />
      </div>
      <div class="prime-modal-content">
        <h6>Provider's name (will be announced to patient):</h6>
        <input class="form-control margin-bottom-ten" maxlength="50" type="text" v-model="providerName" />
      </div>
      <ion-footer class="margin-bottom-twenty">
        <ion-grid>
          <ion-row class="pad-lr-twenty full-height">
            <ion-col class="pad-right-twenty full-height overflow-y-scroll">
              <button @click="closeModal(null)" class="prime-button button-block button-secondary margin-top-ten margin-bottom-ten">Cancel</button>
            </ion-col>
            <ion-col class="full-height overflow-y-scroll">
              <button @click="checkForm" class="prime-button button-block button-primary margin-top-ten margin-bottom-ten">Initiate</button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-footer>
    </div>
    <div class="call-content" v-if="isCallInitiated">
      <ion-card class="calling">
        <div><strong>Patient</strong></div>
        <div v-if="!connected && !ended" class="indicator calling-indicator">
          <span v-for="i in 4" :key="i">&#8226;</span>
        </div>
        <div>
          <img src="../../assets/MyPCP-logo-mark.svg" alt="" width="50" height="50" class="img-logo" />
          <span v-if="connected"
            >Connected
            <!-- <br />
            {{ callTimer }} <br />{{ formatTime(callTimer) }} --></span
          >
          <span v-if="ended">Ended</span>
        </div>
        <div v-if="!connected && !ended" class="indicator calling-indicator-two">
          <span v-for="i in 4" :key="i">&#8226;</span>
        </div>
        <div><strong>Provider</strong></div>
      </ion-card>
      <div class="call-status">
        <ul>
          <li v-for="(step, i) in callProgresses" :key="i">
            {{ step }}
          </li>
        </ul>
      </div>
      <ion-footer class="margin-bottom-twenty" :key="JSON.stringify(callProgresses)">
        <ion-grid>
          <ion-row class="pad-lr-twenty full-height">
            <!-- <ion-col class="pad-right-twenty full-height overflow-y-scroll">
              <button class="prime-button button-block button-secondary margin-top-ten margin-bottom-ten">Cancel Call</button>
            </ion-col> -->
            <ion-col class="full-height overflow-y-scroll">
              <button @click="closeModal(null)" class="prime-button button-block button-primary margin-top-ten margin-bottom-ten">Close (will not end call)</button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-footer>
    </div>
  </div>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import { addIcons } from "ionicons";
import { close } from "ionicons/icons";
import store from "@/store";
import IconPhone from "@/components/Global/Icons/IconPhone";

addIcons({
  "md-close": close.md
});

export default {
  name: "ModalNewCall",
  components: {
    IconPhone
  },
  computed: {
    dbuggaMode() {
      return store.getters["dbugga/dbuggaMode"];
    }
  },
  props: {
    beneAccountId: { type: Number, default: undefined },
    beneficiaryId: { type: Number, default: undefined },
    professional: { type: Object, default: undefined }
  },
  data() {
    return {
      patientNumber: "",
      displayedPatientNumber: "",
      providerNumber: "",
      errorMsg: "",
      isCallInitiated: false,
      callStatus: "",
      callProgresses: [],
      callChecker: null,
      connected: false,
      ended: false,
      callTimer: 0,
      callTimerChecker: null,
      pageCopy: this.$languagePack,
      notifications: [],
      isError: false,
      sid: "",
      providerName: ""
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    connected(newVal, oldVal) {
      if (newVal) {
        this.callTimerChecker = setInterval(() => {
          this.callTimer += 1;
        }, 1000);
      }
    }
  },
  async mounted() {
    const method = "get";
    const path = document.config.accountChannel + this.beneAccountId + "/accountchannel";
    try {
      let { data } = await httpSend({ path, method, authToken: true });
      this.patientNumber = data.find(contact => contact.channel === "sms").identifier || "";
      this.displayedPatientNumber = this.patientNumber.substring(2);
    } catch {
      this.patientNumber = "";
    }
  },
  beforeDestroy() {
    clearInterval(this.callChecker);
    clearInterval(this.callTimerChecker);
  },
  methods: {
    formatTime(seconds) {
      const h = Math.floor(seconds / 3600);
      const m = Math.floor((seconds % 3600) / 60);
      const s = Math.round(seconds % 60);
      return [h, m > 9 ? m : h ? "0" + m : m || "0", s > 9 ? s : "0" + s].filter(Boolean).join(":");
    },
    async initiateCall() {
      this.isCallInitiated = true;

      // demoing purpose only
      // let tcallProgresses = ["Calling Patient", "Patient Answered", "Patient Confirmed", "Calling Provider", "Call Connected"];
      // let currentStep = 0;
      // this.callChecker = setInterval(() => {
      //   if (currentStep < tcallProgresses.length - 1) {
      //     currentStep += 1;
      //     this.callProgresses.push(tcallProgresses[currentStep]);
      //   } else {
      //     this.connected = true;
      //     return;
      //   }
      // }, 5000);
      // END of demoing code

      //real calling of apis start here
      const method = "post";
      const payload = {
        beneficiary_id: this.beneficiaryId,
        initiator: "system",
        urgency: "medium",
        professional_id: this.professional.currentProfessional.professional.id,
        status: "closed",
        subject: "Phone Call"
      };

      const path = document.config.messageApi;
      let cInteractionId;

      await httpSend({ path, payload, method, authToken: true })
        .then(result => {
          cInteractionId = result.data.id;
        })
        .catch(error => {
          khanSolo.log(error);
        });

      const methodVoiceInvoke = "post";
      const pathVoiceInvoke = document.config.voiceInvoke;
      const payloadVoiceInvoke = {
        interactionId: cInteractionId,
        beneAccountId: this.beneAccountId,
        profDisplayName: this.providerName,
        toPhoneNumber: this.patientNumber,
        fromPhoneNumber: this.providerNumber
      };
      try {
        let { data } = await httpSend({ path: pathVoiceInvoke, method: methodVoiceInvoke, payload: payloadVoiceInvoke, authToken: true });
        this.sid = data.sid || "";
      } catch (error) {
        khanSolo.log(error);
      }

      const methodVoiceStatus = "get";
      const pathVoiceStatus = document.config.voiceStatus + this.sid;

      if (this.sid) {
        this.callChecker = setInterval(async () => {
          try {
            let result = await httpSend({ path: pathVoiceStatus, method: methodVoiceStatus, authToken: true });
            if (result.status === 200) {
              let { data } = result;
              let { logEntry, statusCode } = data;

              if (statusCode === "started") {
                this.connected = false;
              } else if (statusCode === "in_progress") {
                this.connected = true;
              } else if (statusCode === "completed" || statusCode === "complete_with_error") {
                this.connected = false;
                this.ended = true;
              }
              if (!this.callProgresses.includes(logEntry)) {
                this.callProgresses.push(logEntry);
              }
            }
          } catch (error) {
            khanSolo.log(error);
          }
        }, 1000);
      }
      // END of real calling of apis start here
    },
    fixPhoneNumber(phNum) {
      return "+1" + phNum.replace(/[^0-9]/g, "");
    },
    checkForm(e) {
      this.notifications = [];
      const regexPhone = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/g;
      this.patientNumber = this.fixPhoneNumber(this.displayedPatientNumber);
      this.providerNumber = this.fixPhoneNumber(this.providerNumber);

      if (!this.patientNumber.match(regexPhone)) {
        this.isError = true;
        this.notifications.push(this.pageCopy.Calls.errors.newCall.patientNumber);
      }
      if (!this.providerNumber.match(regexPhone)) {
        this.isError = true;
        this.notifications.push(this.pageCopy.Calls.errors.newCall.providerNumber);
      }
      if (this.providerName === "") {
        this.isError = true;
        this.notifications.push(this.pageCopy.Calls.errors.newCall.providerName);
      }
      if (!this.notifications.length) {
        this.$ionic.alertController
          .create({
            header: "Confirm Call",
            message: "This will initiate a call between the patient phone number and provider number that you entered.  Once initiated, this call cannot be ended, except by one of the two parties.",
            mode: "ios",
            buttons: [
              {
                text: "No",
                role: "cancel",
                cssClass: "secondary",
                handler: () => {}
              },
              {
                text: "Yes",
                handler: () => {
                  this.initiateCall();
                }
              }
            ]
          })
          .then(a => a.present());
      }
      e.preventDefault();
    },
    closeModal() {
      this.$ionic.modalController.dismiss();
    }
  }
};
</script>

<style scoped>
.prime-modal-content {
  border: none;
  padding: 0 20px 5px;
}

.call-content {
  position: relative;
  height: 73%;
}

ion-footer {
  position: absolute;
  bottom: 0;
}

.calling {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  gap: 30px;
}

.indicator span {
  font-size: 30px;
  color: var(--ion-color-primary);
  animation-name: blink;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  padding: 1px;
}

.calling-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.calling-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

.calling-indicator span:nth-child(4) {
  animation-delay: 0.6s;
}

.calling-indicator-two span:first-child {
  animation-delay: 0.8s;
}

.calling-indicator-two span:nth-child(2) {
  animation-delay: 1.2s;
}

.calling-indicator-two span:nth-child(3) {
  animation-delay: 1.4s;
}

.calling-indicator-two span:nth-child(4) {
  animation-delay: 1.6s;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.connected {
  animation: blinkBg 2s infinite;
  border: 1px solid #fff;
  border-radius: 50%;
}

@keyframes blinkBg {
  0% {
    background: rgb(206, 233, 188);
  }
  20% {
    background: rgb(146, 197, 88);
  }
  100% {
    background: rgb(58, 139, 26);
  }
}

.call-status ul {
  list-style-type: none;
}
.call-status li {
  margin-bottom: 3px;
}

.call-status li:before {
  content: "✔️";
}
.call-status li.working-on-it:before {
  content: " ";
}

.notification-well svg {
  fill: #fff;
  width: 32px;
  margin-right: 10px;
}
.notification-well {
  color: #000;
  font-weight: 500;
  background: var(--ion-color-warning);
  padding: 10px 15px;
  display: flex;
  text-align: left;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;
}
</style>
