<template>
  <ion-card class="margin-death" style="overflow: initial; padding: 0;">
    <!-- mini -->
    <ion-row v-if="mini">
      <common-patient-banner
        style="width: 100%;"
        :patient.prop="this.patientInterface"
        :searchPatient.prop="this.patientConfigData"
        :show_nav_helper.prop="false"
        :context_menu_content.prop="this.contextMenuContentMini"
        :push_to_elation_loading.prop="this.pushToElationLoading"
        :push_to_elation_success.prop="this.pushToElationSuccess"
      />
    </ion-row>
    <!-- standard -->
    <ion-row v-else class="patient-banner-row">
      <div class="common-patient-banner-wrapper">
        <common-patient-banner
          :patient.prop="this.patientInterface"
          :searchPatient.prop="this.patientConfigData"
          :dropdown_content.prop="
            cPatient.patientDetails
              ? [
                  { btnTitle: 'Patient Details', content: showPatientDetails(cPatient) },
                  { btnTitle: 'Contact Info', content: showContactInfo(cPatient) },
                  { btnTitle: 'Provider Info', content: showProviderInfo(cPatient) },
                  { btnTitle: 'Cost / Utilization', content: showCostUtilizationInfo(cPatient) },
                  { btnTitle: 'Payer / Medical Group', content: showPayerInfo(cPatient) },
                  { btnTitle: 'Recent Measurements', content: showRecentMeasurement(cPatient) }
                ]
              : [
                  { btnTitle: 'Member ID', content: showMemberId(cPatient) },
                  { btnTitle: 'Organization', content: showOrganization(cPatient) },
                  { btnTitle: 'Address', content: showAddress(cPatient) },
                  { btnTitle: 'Email', content: showEmail(cPatient) },
                  { btnTitle: 'Home Phone', content: showHomePhone(cPatient) },
                  { btnTitle: 'SMS Phone', content: showSmsPhone(cPatient) }
                ]
          "
          :context_menu_content.prop="this.contextMenuContent"
          :push_to_elation_loading.prop="this.pushToElationLoading"
          :push_to_elation_success.prop="this.pushToElationSuccess"
          :show_nav_helper.prop="$can(I.SEND_MESSAGE) || isNavPatientOverview"
          :nav_helper_enabled.prop="cPatient.enrollmentStatus == 'approved'"
          :nav_helper_function.prop="navHelperFunction"
          :patient_overview_enabled="isNavPatientOverview"
        />
      </div>
    </ion-row>
  </ion-card>
</template>

<script>
import ModalNewCall from "@/components/Global/ModalNewCall.vue";
import ModalNewTask from "@/components/Tasks/ModalNewTask";
import router from "@/router";
import { send as httpSend } from "@/services/Api";
import { addIcons } from "ionicons";
import { moon, settings, square } from "ionicons/icons";
import moment from "moment";
import { engageFhirApi } from "../../../services/EngageFhirApi";
import ModalPatientPDF from "./ModalPatientPDF";
import { I } from "@/utils/permissions";

addIcons({
  "ios-square": square.ios,
  "md-square": square.md,
  "ios-moon": moon.ios,
  "md-moon": moon.md,
  "md-settings": settings.md,
  "ios-settings": settings.ios
});

export default {
  name: "PatientBanner",
  components: {},
  props: ["currentPatient", "mini"],
  data() {
    return {
      isBannerOpen: false,
      keepToolTipVisible: false,
      isButtonClicked: false,
      bannerBgColor: "#ffffff",
      patientInterface: {},
      patientConfigData: { isSearchEnabled: false },
      pushToElationSuccess: undefined,
      pushToElationLoading: false
    };
  },
  mounted() {
    document.addEventListener("create-new-task", this.handleCreateNewTask);
    document.addEventListener("push-to-elation", this.handlePushToElation);
    document.addEventListener("go-to-patient-overview", this.handleGoToPatientOverview);
    document.addEventListener("initiate-phone-call", this.handleInitiatePhoneCall);
    document.addEventListener("print-patient-overview", this.handlePrintPatientOverview);
    document.addEventListener("message-patient", this.handleMessagePatient);

    this.patientInterface = {
      resourceType: "Patient",
      name: [
        {
          family: this.cPatient.account.lastName,
          given: [this.cPatient.account.firstName, this.cPatient.account.middleName],
          suffix: [this.cPatient.account.suffix]
        }
      ],
      gender:
        this.cPatient.beneficiary.gender === "unknown" ||
        this.cPatient.beneficiary.gender === "other"
          ? "not_specified"
          : this.cPatient.beneficiary.gender,
      birthDate: this.cPatient.beneficiary.dob,
      identifier: [
        {
          value: this.cPatient.beneficiary.mrn,
          type: {
            coding: [
              {
                code: "MR"
              }
            ]
          }
        }
      ]
    };
  },
  beforeDestroy() {
    document.removeEventListener("create-new-task", this.handleCreateNewTask);
    document.removeEventListener("push-to-elation", this.handlePushToElation);
    document.removeEventListener("go-to-patient-overview", this.handleGoToPatientOverview);
    document.removeEventListener("initiate-phone-call", this.handleInitiatePhoneCall);
    document.removeEventListener("print-patient-overview", this.handlePrintPatientOverview);
    document.removeEventListener("message-patient", this.handleMessagePatient);
  },
  computed: {
    cPatient() {
      let cntPatient = this.currentPatient.payload
        ? this.currentPatient.payload
        : this.currentPatient;
      return cntPatient;
    },
    cProfessional() {
      return this.$store.state.currentProfessional;
    },
    isNavPatientOverview() {
      return this.$route && this.$route.name === "patientView";
    },
    navHelperFunction() {
      return this.isNavPatientOverview
        ? this.goToPatientGeneralSettings
        : this.checkActiveConversation;
    },
    contextMenuContent() {
      return [
        ...(this.$can(I.SEND_PATIENT_TO_ELATION)
          ? [
              {
                icon: "arrow",
                name: "Push to Elation",
                event: "push-to-elation"
              }
            ]
          : []),
        {
          icon: "phone",
          name: "Initiate Phone Call",
          event: "initiate-phone-call"
        },
        {
          icon: "task",
          name: "Create New Task",
          event: "create-new-task"
        },
        {
          icon: "print",
          name: "Print Patient Overview PDF",
          event: "print-patient-overview"
        }
      ];
    },
    contextMenuContentMini() {
      return [
        {
          icon: "grid",
          name: "Go To Patient Overview",
          event: "go-to-patient-overview"
        },
        {
          icon: "conversation",
          name: "Message Patient",
          event: "message-patient"
        },
        {
          icon: "task",
          name: "Create New Task",
          event: "create-new-task"
        },
        {
          icon: "print",
          name: "Print Patient Overview PDF",
          event: "print-patient-overview"
        }
      ];
    }
  },
  methods: {
    handleCreateNewTask(e) {
      e.stopImmediatePropagation();
      this.$ionic.modalController.dismiss();
      this.openNewTaskModal("Create a New Task");
    },
    async handlePushToElation(e) {
      e.stopImmediatePropagation();
      await this.pushToElation();
    },
    handleGoToPatientOverview(e) {
      e.stopImmediatePropagation();
      this.$ionic.modalController.dismiss();
      this.goToPatientGeneralSettings();
    },
    handleInitiatePhoneCall(e) {
      e.stopImmediatePropagation();
      this.openCallModal(this.cPatient.account.id, this.cPatient.beneficiary.id);
    },
    handlePrintPatientOverview(e) {
      e.stopImmediatePropagation();
      this.$ionic.modalController.dismiss();
      this.openPrintPatientModal("overview PDF");
    },
    handleMessagePatient(e) {
      e.stopImmediatePropagation();
      this.$ionic.modalController.dismiss();
      this.checkActiveConversation();
    },
    expandBanner() {
      this.isBannerOpen = !this.isBannerOpen;
    },
    showSettings: function() {
      router.push({
        path: "/patient-settings/general-info/" + this.currentPatient.payload.beneficiary.id
      });
    },
    makePcpName(assignedProfessional) {
      if (this.currentPatient.payload) {
        let title = assignedProfessional.title ? assignedProfessional.title + " " : "";
        let fName = assignedProfessional.firstName ? assignedProfessional.firstName + " " : "";
        let lName = assignedProfessional.lastName ? assignedProfessional.lastName + " " : "";
        return title + fName + lName;
      }
    },
    makeAddress(address) {
      let street = address.address1 ? address.address1 + " " : "";
      let city = address.city ? address.city + ", " : "";
      let state = address.state ? address.state + " " : "";
      let zip = address.zipCode ? address.zipCode : "";
      return street + city + state + zip;
    },
    formatDate(date) {
      date = Date.parse(date);
      return moment(new Date(date)).format("MM/DD/YYYY");
    },
    formatMoney(val) {
      try {
        if (!isNaN(val)) {
          val =
            "$" +
            val.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        } else {
          val = "-";
        }
      } catch (error) {
        val = "-";
      }
      return val;
    },
    formatPercent(val) {
      if (val) {
        if (!isNaN(val)) {
          val =
            val.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) +
            "%";
        }
      } else {
        val = "-";
      }
      return val;
    },
    formatPhone(phoneNumberString) {
      try {
        let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return "(" + match[1] + ") " + match[2] + "-" + match[3];
        }
        return null;
      } catch (error) {
        return null;
      }
    },
    showOrganization(cPatient) {
      const organization =
        cPatient.organization && cPatient.organization.name ? cPatient.organization.name : "-";
      return [{ header: null, data: organization }];
    },
    showMemberId(cPatient) {
      const memberId =
        cPatient.beneficiary && cPatient.beneficiary.mrn ? cPatient.beneficiary.mrn : "-";
      return [{ header: null, data: memberId }];
    },
    showEmail(cPatient) {
      const email =
        cPatient.accountChannels && cPatient.accountChannels.find(obj => obj.channel == "email")
          ? cPatient.accountChannels.find(obj => obj.channel == "email").identifier
          : "-";
      return [{ header: null, data: email }];
    },
    showAddress(cPatient) {
      const address = cPatient.address ? this.makeAddress(cPatient.address) : "-";
      return [{ header: null, data: address }];
    },
    showSmsPhone(cPatient) {
      const smsPhone =
        cPatient.accountChannels && cPatient.accountChannels.find(obj => obj.channel == "sms")
          ? this.formatPhone(
              cPatient.accountChannels
                .find(obj => obj.channel == "sms")
                .identifier.replace("+", "")
                .substring(1)
            )
          : "-";
      return [{ header: null, data: smsPhone }];
    },
    showHomePhone(cPatient) {
      const homePhone =
        cPatient.accountChannels && cPatient.accountChannels.find(obj => obj.channel == "voice")
          ? this.formatPhone(
              cPatient.accountChannels
                .find(obj => obj.channel == "voice")
                .identifier.replace("+", "")
                .substring(1)
            )
          : "-";
      return [{ header: null, data: homePhone }];
    },
    yearsOld() {
      moment.relativeTimeRounding(Math.floor);

      const { beneficiary } = this.cPatient;
      const dobFormat = moment(beneficiary.dob).fromNow(true);
      return dobFormat;
    },
    showPatientDetails(cPatient) {
      const patientDetailObject = [
        { header: "ID", data: cPatient.beneficiary.mrn ? cPatient.beneficiary.mrn : "-" },
        {
          header: "Member ID",
          data: cPatient.patientDetails.mbr_id ? cPatient.patientDetails.mbr_id : "-"
        },
        {
          header: "EMPI",
          data: cPatient.patientDetails.empi ? cPatient.patientDetails.empi : "-",
          showDivider: true
        },
        {
          header: "Patient Ethnicity",
          data: cPatient.patientDetails.mbr_ethnicity ? cPatient.patientDetails.mbr_ethnicity : "-"
        },
        {
          header: "Patient Language",
          data: cPatient.patientDetails.mbr_language ? cPatient.patientDetails.mbr_language : "-",
          showDivider: true
        },
        {
          header: "Low Income Subsidy (LIS) Indicator (Most Recent)",
          data: cPatient.patientDetails.mbr_lis_indicator
            ? cPatient.patientDetails.mbr_lis_indicator
            : "-"
        },
        {
          header: "Low Income Subsidy (LIS) Amount (Most Recent)",
          data: this.formatPercent(cPatient.patientDetails.mbr_lis_amt)
        },
        {
          header: "Is Hospice Patient",
          data: cPatient.patientDetails.hospice_indicator
            ? cPatient.patientDetails.hospice_indicator
            : "-"
        },
        {
          header: "Is ESRD Patient",
          data: cPatient.patientDetails.esrd_indicator
            ? cPatient.patientDetails.esrd_indicator
            : "-"
        },
        {
          header: "Is Long Term Care Patient",
          data: cPatient.patientDetails.long_term_care_facility_indicator
            ? cPatient.patientDetails.long_term_care_facility_indicator
            : "-"
        },
        { header: "Dual Eligible", data: cPatient.patientDetails.dual_eligible_indicator }
      ];

      return patientDetailObject;
    },
    showProviderInfo(cPatient) {
      const providerInfoObject = [
        {
          header: "Organization",
          data:
            cPatient.organization && cPatient.organization.name ? cPatient.organization.name : "-"
        },
        {
          header: "Primary Provider",
          data:
            cPatient.patientDetails.clinical_provider_first_name ||
            cPatient.patientDetails.clinical_provider_last_name
              ? (cPatient.patientDetails.clinical_provider_first_name
                  ? cPatient.patientDetails.clinical_provider_first_name
                  : "") +
                (cPatient.patientDetails.clinical_provider_last_name
                  ? " " + cPatient.patientDetails.clinical_provider_last_name
                  : "")
              : "-"
        },
        { header: "Physician Group", data: "" }
      ];

      return providerInfoObject;
    },

    showRecentMeasurement(cPatient) {
      const recentMeaObject = [
        {
          header: "Patient Height (with last date assessed)",
          data: cPatient.patientDetails.mbr_height
            ? `${cPatient.patientDetails.mbr_height} (${this.formatDate(
                cPatient.patientDetails.date_height_last_measured
              )})`
            : "-"
        },
        {
          header: "Patient Weight (with last date assessed)",
          data: cPatient.patientDetails.mbr_weight
            ? `${cPatient.patientDetails.mbr_weight} (${this.formatDate(
                cPatient.patientDetails.date_weight_last_measured
              )})`
            : "-"
        },
        {
          header: "Patient BMI (with last date assessed)",
          data: cPatient.patientDetails.mbr_bmi
            ? `${cPatient.patientDetails.mbr_bmi} (${this.formatDate(
                cPatient.patientDetails.date_bmi_last_measured
              )})`
            : "-"
        }
      ];
      return recentMeaObject;
    },
    showContactInfo(cPatient) {
      const contactInfoObject = [
        {
          header: "SMS Phone",
          data:
            cPatient.accountChannels && cPatient.accountChannels.find(obj => obj.channel == "sms")
              ? this.formatPhone(
                  cPatient.accountChannels
                    .find(obj => obj.channel == "sms")
                    .identifier.replace("+", "")
                    .substring(1)
                )
              : "-"
        },
        {
          header: "Home Phone",
          data:
            cPatient.accountChannels && cPatient.accountChannels.find(obj => obj.channel == "voice")
              ? this.formatPhone(
                  cPatient.accountChannels.find(obj => obj.channel == "voice").identifier
                )
              : "-"
        },
        {
          header: "Email",
          data:
            cPatient.accountChannels && cPatient.accountChannels.find(obj => obj.channel == "email")
              ? cPatient.accountChannels.find(obj => obj.channel == "email").identifier
              : "-"
        },
        { header: "Address", data: cPatient.address ? this.makeAddress(cPatient.address) : "-" }
      ];

      return contactInfoObject;
    },
    showCostUtilizationInfo(cPatient) {
      const costUtilizationInfoObject = [
        {
          header: "Total Cost",
          data: `YTD: ${this.formatMoney(
            cPatient.patientDetails.ytd_total_cost
          )}\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0R12: ${this.formatMoney(
            cPatient.patientDetails.r12_total_cost
          )}`
        },
        {
          header: "Health Plan Medical Cost",
          data: `YTD: ${this.formatMoney(
            cPatient.patientDetails.ytd_medical_plan_cost
          )}\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0R12: ${this.formatMoney(
            cPatient.patientDetails.r12_medical_plan_cost
          )}`
        },
        {
          header: "Health Plan Rx Cost",
          data: `YTD: ${this.formatMoney(
            cPatient.patientDetails.ytd_rx_plan_cost
          )}\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0R12: ${this.formatMoney(
            cPatient.patientDetails.r12_rx_plan_cost
          )}`
        },
        {
          header: "Member Medical Cost",
          data: `YTD: ${this.formatMoney(
            cPatient.patientDetails.ytd_medical_member_cost
          )}  \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0R12: ${this.formatMoney(
            cPatient.patientDetails.r12_medical_member_cost
          )}`
        },
        {
          header: "Member Rx Cost",
          data: `YTD:${this.formatMoney(
            cPatient.patientDetails.ytd_rx_member_cost
          )} \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0R12: ${this.formatMoney(
            cPatient.patientDetails.r12_rx_member_cost
          )}`
        },
        {
          header: "ER Visits",
          data: `YTD: ${
            cPatient.patientDetails.ytd_num_er_visits
              ? cPatient.patientDetails.ytd_num_er_visits
              : "-"
          } \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0R12: ${
            cPatient.patientDetails.r12_num_er_visits
              ? cPatient.patientDetails.r12_num_er_visits
              : "-"
          }`
        },
        {
          header: "Avoidable ER Visits",
          data: `YTD:${
            cPatient.patientDetails.ytd_num_avoidable_er_visits
              ? cPatient.patientDetails.ytd_num_avoidable_er_visits
              : "-"
          } \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0R12: ${
            cPatient.patientDetails.r12_num_avoidable_er_visits
              ? cPatient.patientDetails.r12_num_avoidable_er_visits
              : "-"
          }`
        },
        {
          header: "Acute Admits",
          data: `YTD: ${
            cPatient.patientDetails.ytd_num_acute_admits
              ? cPatient.patientDetails.ytd_num_acute_admits
              : "-"
          } \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0R12: ${
            cPatient.patientDetails.r12_num_acute_admits
              ? cPatient.patientDetails.r12_num_acute_admits
              : "-"
          }`
        },
        {
          header: "Accountable Provider Visits",
          data: `YTD: ${
            cPatient.patientDetails.ytd_num_accountable_visits
              ? cPatient.patientDetails.ytd_num_accountable_visits
              : "-"
          } \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0R12: ${
            cPatient.patientDetails.r12_num_accountable_visits
              ? cPatient.patientDetails.r12_num_accountable_visits
              : "-"
          }`
        },
        {
          header: "Last Accountable Provider Visit (Date)",
          data: cPatient.patientDetails.accountable_visit_date
            ? this.formatDate(cPatient.patientDetails.accountable_visit_date)
            : "-"
        },
        {
          header: "Specialist Visits",
          data: `YTD: ${
            cPatient.patientDetails.ytd_num_specialist_visits
              ? cPatient.patientDetails.ytd_num_specialist_visits
              : "-"
          } \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0R12: ${
            cPatient.patientDetails.r12_num_specialist_visits
              ? cPatient.patientDetails.r12_num_specialist_visits
              : "-"
          }`
        },
        {
          header: "Non-Specialist Visits",
          data: `YTD: ${
            cPatient.patientDetails.ytd_num_non_specialist_visits
              ? cPatient.patientDetails.ytd_num_non_specialist_visits
              : "-"
          } \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0R12: ${
            cPatient.patientDetails.r12_num_non_specialist_visits
              ? cPatient.patientDetails.r12_num_non_specialist_visits
              : "-"
          } `
        },
        {
          header: "RAF Score Part C",
          data: cPatient.patientDetails.part_c_raf_score
            ? cPatient.patientDetails.part_c_raf_score
            : "-"
        },
        {
          header: "RAF Score Part D",
          data: cPatient.patientDetails.part_d_raf_score
            ? cPatient.patientDetails.part_d_raf_score
            : "-"
        }
      ];
      return costUtilizationInfoObject;
    },
    showPayerInfo(cPatient) {
      const payerInfoObject = [
        {
          header: "Organization",
          data:
            cPatient.organization && cPatient.organization.name ? cPatient.organization.name : "-"
        },
        {
          header: "Payer",
          data: cPatient.patientDetails.payer ? cPatient.patientDetails.payer : "-"
        },
        {
          header: "Primary Provider",
          data:
            cPatient.patientDetails.clinical_provider_first_name ||
            cPatient.patientDetails.clinical_provider_last_name
              ? (cPatient.patientDetails.clinical_provider_first_name
                  ? cPatient.patientDetails.clinical_provider_first_name
                  : "") +
                (cPatient.patientDetails.clinical_provider_last_name
                  ? " " + cPatient.patientDetails.clinical_provider_last_name
                  : "")
              : "-"
        },
        { header: "Physician Group", data: "" },
        {
          header: "Line of Business",
          data: cPatient.patientDetails.line_of_business
            ? cPatient.patientDetails.line_of_business
            : "-"
        },
        {
          header: "Health Plan Effective Date",
          data: cPatient.patientDetails.mbr_plan_start_date
            ? this.formatDate(cPatient.patientDetails.mbr_plan_start_date)
            : "-"
        },
        {
          header: "Product",
          data: cPatient.patientDetails.product ? cPatient.patientDetails.product : "-"
        },
        {
          header: "Contract PBP",
          data: cPatient.patientDetails.mbr_contract_pbp
            ? cPatient.patientDetails.mbr_contract_pbp
            : "-"
        },
        {
          header: "Employer Group",
          data: cPatient.patientDetails.mbr_group ? cPatient.patientDetails.mbr_group : "-"
        }
      ];

      return payerInfoObject;
    },
    goToPatientGeneralSettings() {
      const id = this.cPatient.beneficiary.id;
      this.$router.push(`/patient-settings/general-info/${id}`);
    },
    checkActiveConversation() {
      const method = "get";
      const { beneficiary } = this.cPatient;
      const path = document.config.patientApi + beneficiary.id + "/conversations?status=active";

      httpSend({ path, method, authToken: true })
        .then(response => {
          const interactionId = response.data[0] ? response.data[0].id : undefined;
          interactionId ? this.goToConversation(interactionId) : this.goToConversation();
        })
        .catch(error => {
          khanSolo.log(error);
        });
    },
    openNewTaskModal(title) {
      this.$ionic.modalController
        .create({
          component: ModalNewTask,
          mode: "ios",
          componentProps: {
            propsData: {
              title: title,
              beneficiaryId: this.cPatient.beneficiary.id
            }
          }
        })
        .then(m => m.present());
    },
    openPrintPatientModal(pdfType) {
      this.$ionic.modalController
        .create({
          component: ModalPatientPDF,
          mode: "ios",
          cssClass: "prime-modal-xwide",
          componentProps: {
            propsData: {
              pdfType,
              beneficiaryId: `${this.cPatient.beneficiary.id}`,
              accountId: this.cPatient.beneficiary.accountId
            }
          }
        })
        .then(m => m.present());
    },
    openCallModal(beneAccountId, beneficiaryId) {
      this.$ionic.modalController
        .create({
          component: ModalNewCall,
          mode: "ios",
          componentProps: {
            propsData: {
              beneAccountId,
              beneficiaryId,
              professional: this.cProfessional
            }
          },
          backdropDismiss: false
        })
        .then(m => m.present());
    },
    async pushToElation() {
      this.pushToElationLoading = true;
      const response = await engageFhirApi.post(
        `/elation-integration/${this.cPatient.beneficiary.id}`,
        {}
      );
      this.pushToElationSuccess = typeof response !== "undefined";
      this.pushToElationLoading = false;
    },
    goToConversation() {
      router.push({
        path: "/professional?patient=" + this.currentPatient.payload.beneficiary.id
      });
    }
  }
};
</script>

<style scoped lang="scss">
.patient-banner-row {
  width: 100%;
  flex-wrap: nowrap;
}

.common-patient-banner-wrapper {
  min-height: 65px;
  width: 100%;
}

@media (max-width: 480px) {
  .patient-banner-row {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
  }
  .common-patient-banner-wrapper {
    width: 100%;
  }
}
</style>
