<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <IconCheckCircle slot="start" class="icon-small" />
        <h5 slot="start">{{ title }}</h5>
        <ion-button
          slot="end"
          mode="ios"
          @click="closeModal(null)"
          expand="full"
          color="primary"
          fill="clear"
          ><ion-icon name="md-close"></ion-icon
        ></ion-button>
      </ion-toolbar>
    </ion-header>
    <!-- form validation errors -->
    <div
      class="margin-lr-twenty prime-modal-patient-results-notifications"
      v-if="notifications.length"
    >
      <div
        v-if="notifications.length > 0"
        :class="{ 'danger-well': isError }"
        class="margin-bottom-ten notifications-well"
      >
        <div v-for="(notification, key) in notifications" :key="key">
          {{ notification }}
        </div>
      </div>
    </div>
    <div v-if="!selectedPatient" class="prime-modal-content">
      <h6>Choose a patient to create the task for:</h6>
      <input
        class="form-control margin-bottom-ten"
        type="text"
        placeholder="Search all patients..."
        v-model="searchCriteria"
      />
    </div>
    <div v-if="selectedPatient" class="prime-modal-content patient-selected">
      <h6>Patient:</h6>
      <ion-card class="prime-patient-card ion-margin-bottom">
        <div class="avatar" v-if="selectedPatient.beneFirstName && selectedPatient.beneLastName">
          {{ selectedPatient.beneFirstName.charAt(0) + selectedPatient.beneLastName.charAt(0) }}
        </div>
        <div>
          <div class="prime-patient-card-name">
            {{ selectedPatient.beneLastName + ", " + selectedPatient.beneFirstName }}
          </div>
          <div class="prime-patient-card-info">
            <strong>ID: </strong><span class="mrn-holder">{{ selectedPatient.mrn }}</span>
            <div class="display-inline-block">
              <strong>PCP: </strong
              >{{
                selectedPatient.pcpTitle
                  | formatDisplayName(
                    selectedPatient.pcpFirstName,
                    selectedPatient.pcpLastName,
                    selectedPatient.suffix
                  )
              }}
            </div>
          </div>
        </div>
      </ion-card>
      <div v-if="selectedPatient" class="login-form">
        <div class="block-loader" v-if="isLoadingForm">
          <div
            class="centerItems text-center display-flex valign full-height margin-top-twenty full-width"
          >
            <span>
              <ion-spinner name="lines" class="spinner-medium" color="primary"></ion-spinner>
            </span>
          </div>
        </div>
        <div v-else>
          <h6>Task Title:</h6>
          <input
            class="form-control"
            type="text"
            placeholder="Type Subject here..."
            v-model="dataModel.name"
            :maxlength="maxTitleChars"
          />
          <h6>Assign Task To:</h6>
          <div>
            <ion-row>
              <ion-col class="pad-lr-zero pad-top-zero">
                <input
                  id="searchSpot"
                  ref="searchSpot"
                  autocomplete="off"
                  class="form-control"
                  v-model="name"
                  placeholder="Search Care Team..."
                  @keyup="filterInput"
                  @click="filterInput"
                />
              </ion-col>
            </ion-row>
            <ion-list mode="ios" ref="assignedList" class="assigned-list">
              <div
                v-if="availableProfessionals && availableProfessionals.length < 1"
                lines="none"
                class="text-muted text-center pad-twenty"
              >
                No available care team members
              </div>
            
              <ul class="professional-list" v-else>
                <li
                  v-for="(professional, index) in filteredProfessionals"
                  :key="professional.id"
                  lines="none"
                  @mouseover="hoverSelect(index)"
                  @click="selectAssignee(professional)"
                  :class="
                    index === selectedIndex ? 'pcp-selected-item curor-pointer' : 'curor-pointer'
                  "
                >
                  <ion-label
                    v-html="
                      highLight(professional.account.firstName) +
                        ' ' +
                      highLight(professional.account.lastName)
                    "
                  ></ion-label>
                </li>
              </ul>
            </ion-list>
          </div>
          <h6>Task Description</h6>
          <textarea
            placeholder="Type message here..."
            class="form-control"
            rows="5"
            v-model="dataModel.description"
          ></textarea>
          <h6>Related to Program (optional)</h6>
          <select @change="selectProgram($event)" class="form-control state">
            <option value="" selected>Select a Program</option>
            <option v-for="(program, key) in programs" :key="key" :value="program.program.id">{{
              program.program.name
            }}</option>
          </select>
          <h6>Associated Conversation (optional)</h6>
          <select @change="selectConvo($event)" class="form-control state">
            <option value="" selected>Select a Converation</option>
            <option v-for="(convo, key) in recentConvos" :key="key" :value="convo.id"
              >{{ convo.description }}{{ formatDate(convo.last_message_date) }}</option
            >
          </select>
          <div class="margin-top-twenty button-group">
            <button
              class="prime-button button-block"
              @click="checkForm"
              :class="{ 'button-pending': isLoading }"
            >
              <span>Create Task</span>
              <ion-spinner name="dots" duration="1200" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <ion-content v-if="!selectedPatient" class="prime-patient-results-container">
      <div class="block-loader" v-if="isLoadingPatients">
        <div
          class="centerItems text-center display-flex valign full-height margin-top-twenty full-width"
        >
          <span>
            <ion-spinner name="lines" class="spinner-medium" color="primary"></ion-spinner>
          </span>
        </div>
      </div>

      <div class="prime-modal-patient-results">
        <div v-if="patientSearchResults">
          <ion-card
            v-for="(patient, key) in patientSearchResults"
            :key="key"
            @click="selectPatient(patient)"
            class="prime-patient-card"
          >
            <div class="avatar" v-if="patient.beneFirstName && patient.beneLastName">
              {{ patient.beneFirstName.charAt(0) + patient.beneLastName.charAt(0) }}
            </div>
            <div>
              <div class="prime-patient-card-name">
                {{ patient.beneLastName + ", " + patient.beneFirstName }}
              </div>
              <div class="prime-patient-card-info">
                <strong>ID: </strong><span class="mrn-holder">{{ patient.mrn }}</span>
                <div class="display-inline-block">
                  <strong>PCP: </strong
                  >{{
                    patient.pcpTitle |  formatDisplayName( patient.pcpFirstName, patient.pcpLastName, patient.suffix)
                  }}
                </div>
              </div>
            </div>
          </ion-card>
        </div>
        <small v-if="!searchCriteria && !isLoadingPatients">Previewing 10 patients.</small>
      </div>
    </ion-content>
  </div>
</template>

<script>
import IconCheckCircle from "@/components/Global/Icons/IconCheckCircle";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events.js";
import store from "@/store";
import { addIcons } from "ionicons";
import { close } from "ionicons/icons";
import _ from "lodash";
import moment from "moment";

addIcons({
  "md-close": close.md
});

export default {
  name: "ModalNewTask",
  components: {
    IconCheckCircle
  },
  computed: {
    dbuggaMode() {
      return store.getters["dbugga/dbuggaMode"];
    }
  },
  props: {
    title: { type: String, default: "Create New Task" },
    beneficiaryId: { type: Number, default: undefined }
  },
  data() {
    return {
      searchCriteria: undefined,
      patientSearchResults: undefined,
      selectedPatient: undefined,
      notifications: [],
      isError: false,
      isLoading: false,
      isLoadingPatients: false,
      isLoadingForm: false,
      pageCopy: this.$languagePack,
      maxTitleChars: 99,
      careTeamByPatient: [],
      availableProfessionals: [],
      filteredProfessionals: [],
      selectedIndex: -1,
      searchSpot: null,
      searchedProfessional: "",
      assignToProfessionalName: undefined,
      name: "",
      dataModel: {
        assignedToProfessionalId: null,
        beneficiaryId: 0,
        description: null,
        dueDate: null,
        interactionId: null,
        name: null,
        priority: "normal",
        programId: 1,
        status: null,
        typeId: 6000
      },
      programs: [],
      recentConvos: []
    };
  },
  watch: {
    searchCriteria: function() {
      this.patientSearchResults = [];
      if (this.searchCriteria.length > 0 || this.searchCriteria.length == 0) {
        this.notifications = [];
        this.debounceInput();
      } else if (this.searchCriteria.length > 0 || this.searchCriteria.length <= 3) {
        this.patientSearchResults = null;
      }
    }
  },
  mounted: function() {
    // load preview batch of patients
    if (this.beneficiaryId) {
      this.preSelectPatient(this.beneficiaryId);
      this.getPrograms(this.beneficiaryId);
      this.getRecentConvos(this.beneficiaryId);
    } else {
      this.searchPatients();
    }
    this.filteredProfessionals = this.availableProfessionals;

   
  },
  methods: {
    getPrograms(beneficiaryID) {
      const method = "get";
      const path = document.config.patientApi + beneficiaryID + "/programs";

      httpSend({ path, method, authToken: true })
        .then(response => {
          let rtnArr = response.data;
          if (rtnArr.length > 0) {
            // first remove text triage
            this.allAvailablePrograms = rtnArr.filter(e => e.accountProgram.programId !== 1);
            //active programs
            this.programs = this.allAvailablePrograms.filter(e => e.accountProgram.active == true);
          }
        })
        .catch(error => {
          khanSolo.log(error);
        });
    },
    getRecentConvos(beneficiaryID) {
      this.loadingConvos = true;
      const method = "get";
      const path = document.config.patientApi + beneficiaryID + "/conversations";

      httpSend({ path, method, authToken: true })
        .then(response => {
          this.recentConvos = response.data;
        })
        .catch(error => {
          khanSolo.log(error);
        });
    },
    preSelectPatient: async function(beneficiaryId) {
      const method = "get";
      const path = document.config.currentPatient + beneficiaryId;

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.patientSearchResults = [];

          const patient = {
            beneficiaryId: result.data.beneficiary.id,
            beneFirstName: result.data.account.firstName,
            beneLastName: result.data.account.lastName,
            mrn: result.data.beneficiary.mrn,
            pcpTitle: result.data.professionalAccount.title,
            pcpFirstName: result.data.professionalAccount.firstName,
            pcpLastName: result.data.professionalAccount.lastName
          };

          this.selectPatient(patient);
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not get current patient. Please try again later.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    selectPatient: function(patient) {
      // empty the search Results list in favor of selected patient
      this.getPrograms(patient.beneficiaryId);
      this.getRecentConvos(patient.beneficiaryId);
      this.patientSearchResults = [];
      this.patientSearchResults.push(patient);
      // set selected patient stuffs
      this.getCareTeam(patient.beneficiaryId);
      this.dataModel.beneficiaryId = patient.beneficiaryId;
      this.selectedPatient = patient;
      this.filteredProfessionals= this.availableProfessionals;
    },
    highLight(cInput) {
      const regex = new RegExp(this.name, "i");
      let matchingString = cInput.match(regex);
      return matchingString
        ? cInput.replace(matchingString, `<strong>${matchingString}</strong>`)
        : cInput;
    },
    filterInput(e) {
      this.searchedProfessional = "";
      const regex = new RegExp(this.name, "gi");
      if (this.name !== "") {
        let tempFilterProfessionals = this.availableProfessionals.filter(
          entry => entry.account.firstName.match(regex) || entry.account.lastName.match(regex)
        );
        if (this.filteredProfessionals.length !== tempFilterProfessionals.length) {
          this.selectedIndex = -1;
        }
        this.filteredProfessionals = tempFilterProfessionals;
      } else {
        this.filteredProfessionals = this.availableProfessionals;
      }
      if (e.key === "ArrowDown") {
        this.selectedIndex =
          this.filteredProfessionals.length - 1 === this.selectedIndex ? 0 : this.selectedIndex + 1;
        this.searchedProfessional =
          this.filteredProfessionals[this.selectedIndex].account.firstName +
          " " +
          this.filteredProfessionals[this.selectedIndex].account.lastName;
        this.searchSpot.placeholder = this.searchedProfessional;
      }
      if (e.key === "ArrowUp") {
        this.selectedIndex =
          this.selectedIndex > 0 ? this.selectedIndex - 1 : this.filteredProfessionals.length - 1;
        this.searchedProfessional =
          this.filteredProfessionals[this.selectedIndex].account.firstName +
          " " +
          this.filteredProfessionals[this.selectedIndex].account.lastName;
        this.searchSpot.placeholder = this.searchedProfessional;
      }

      if (e.key === "Enter") {
        this.name = this.searchedProfessional;
        this.selectAssignee(this.filteredProfessionals[this.selectedIndex]);
      }
    },
    hoverSelect(i) {
      this.selectedIndex = i;
      this.searchedProfessional =
        this.filteredProfessionals[this.selectedIndex].account.firstName +
        " " +
        this.filteredProfessionals[this.selectedIndex].account.lastName;

      this.searchSpot = document.getElementById("searchSpot");
      this.searchSpot.placeholder = this.searchedProfessional;
    },
    getCareTeam(beneId) {
      this.isLoadingForm = true;
      const method = "get";
      const path = document.config.careTeam + beneId;

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.careTeamByPatient = result.data.sort((a, b) =>
            a.account.firstName.localeCompare(b.account.firstName)
          );
          this.availableProfessionals = result.data.sort((a, b) =>
            a.account.firstName.localeCompare(b.account.firstName)
          );
        })
        .catch(error => {
          this.catchError(error, "Could not get comments. Please try again later.");
        })
        .finally(() => {
          this.isLoadingForm = false;
        });
    },
    selectAssignee(professional) {
      this.assignToProfessionalName = professional.account.firstName + " " + professional.account.lastName;
      this.filteredProfessionals = [];
      this.dataModel.assignedToProfessionalId = professional.id;
    },
    selectProgram(evt) {
      this.dataModel.programId = evt.target.value;
    },
    selectConvo(evt) {
      this.dataModel.interactionId = evt.target.value;
    },
    debounceInput: _.debounce(function() {
      this.searchPatients();
    }, 400),
    async searchPatients() {
      this.isLoadingPatients = true;
      const method = "post";
      const claims = await this.$authState.claims();
      const path = document.config.patientsApi + claims.professionalId;

      var payload = {
        page: "1",
        perPage: "10",
        columnFilters: {
          beneLastName: this.searchCriteria,
          beneFirstName: this.searchCriteria,
          mrn: this.searchCriteria,
          dob: this.searchCriteria,
          orgId: this.$authState.getOrgId() ? this.$authState.getOrgId() : claims.orgIds
        },
        sort: [{ field: "beneLastName", type: "asc" }]
      };

      httpSend({ path, method, authToken: true, payload })
        .then(result => {
          this.patientSearchResults = result.data.results;
          this.isLoadingPatients = false;
        })
        .catch(error => {
          this.isLoadingPatients = false;
          khanSolo.log(error);
        });
    },
    checkForm: function(e) {
      this.notifications = [];

      if (!this.dataModel.name) {
        this.isError = true;
        this.notifications.push(this.pageCopy.Tasks.errors.newTask.title);
      }
      if (!this.dataModel.description) {
        this.isError = true;
        this.notifications.push(this.pageCopy.Tasks.errors.newTask.description);
      }
      if (!this.notifications.length) {
        this.createTask();
      }
      e.preventDefault();
    },
    createTask() {
      this.isLoading = true;
      const method = "post";
      const path = document.config.task;

      var payload = this.dataModel;

      this.dataModel.status = this.changeStatus(this.dataModel.assignedToProfessionalId);

      httpSend({ path, method, authToken: true, payload })
        .then(() => {
          EventBus.$emit("getTasks");
          this.$ionic.modalController.dismiss();
          this.isLoading = false;
        })
        .catch(async error => {
          const toast = await this.$ionic.toastController.create({
            header: "Error",
            message: "Task could not be created. Please try again later",
            duration: 5000,
            position: "top"
          });
          toast.present();

          this.isLoading = false;
          khanSolo.log(error);
        });
    },
    changeStatus(assignProfId) {
      if (assignProfId != null) {
        return "assigned";
      }
    },
    closeModal() {
      this.$ionic.modalController.dismiss();
    },
    formatDate(date) {
      return date ? moment(date).format(" - M/DD/YYYY") : "";
    }
  }
};
</script>

<style scoped>
.block-loader {
  height: 200px;
}
.prime-modal .prime-modal-content.patient-selected {
  height: 100%;
  padding-bottom: 20px;
  border-bottom: none;
}
.prime-modal-patient-results {
  padding: 20px 0 10px;
}
.prime-modal ion-content.prime-patient-results-container {
  --background: var(--ion-modal-secondary-background);
}
.prime-modal
  ion-content.prime-patient-results-container
  .prime-modal-patient-results-notifications {
  margin: 0 20px;
}
.prime-modal-patient-results small {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  display: block;
  color: var(--ion-color-medium-shade);
  margin: 20px 0 10px;
}
ion-card.prime-patient-card {
  background: #ffffff;
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
ion-card.prime-patient-card:hover {
  box-shadow: 0 3px 15px 0px rgba(46, 61, 73, 0.3) !important;
}
ion-card.prime-patient-card .avatar {
  min-width: 60px;
  height: 60px;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 20px;
}
ion-card.prime-patient-card .prime-patient-card-name {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card.prime-patient-card .prime-patient-card-info {
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
  color: var(--ion-color-medium-shade);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card.prime-patient-card .prime-patient-card-icons {
  display: flex;
  position: absolute;
  right: 20px;
  top: 15px;
}
ion-card.prime-patient-card .prime-patient-card-icons img {
  width: 22px;
  margin: 0 0 0 10px;
}
.prime-modal-content.patient-selected .prime-patient-card {
  margin-bottom: 25px !important;
}
.prime-modal-content.patient-selected {
  overflow-y: auto;
}
.prime-modal-content.patient-selected .form-sub-label {
  font-size: 12px;
  font-weight: 500;
  margin-top: -8px;
  margin-bottom: 5px;
  color: var(--ion-color-medium-shade);
}
.dropdown-button {
  width: 60px;
}
.button-group {
  display: flex;
}

.mrn-holder {
  display: inline-block;
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: bottom;
}
.mrn-holder::after {
  content: " ";
  margin-right: 5px;
}

ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}
ul.professional-list {
  list-style-type: none;
  padding: 0;
  max-height: 300px;
  overflow-y: scroll;
  margin-bottom: 0;
}

.professional-list li {
  padding: 10px 20px;
  font-size: 16px;
}

.professional-list li:hover {
  background: var(--ion-color-light);
  cursor: pointer;
}

.pcp-selected-item {
  background: var(--ion-color-light);
}
</style>
