<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <IconPrint slot="start" class="icon-small margin-right-five" />
        <h5>Print Patient {{ pdfType }}</h5>
        <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="medium" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content class="prime-modal-content">
      <div v-if="pdfType === 'overview PDF'" class="margin-bottom-twenty">
        <p>
          Instructions: Select which sections and time frame to print. The labels show the number of records that will print per action.
        </p>
        <ul class="counts" v-if="Object.keys(counts).length > 0">
          <li v-for="(count, key) in counts" :key="key">
            <ion-row>
              <ion-col size="0.4">
                <div class="checkbox">
                  <input type="checkbox" :disabled="key === 'Patient Banner'" :class="key === 'Patient Banner' ? 'discheck' : ''" :id="key" color="primary" :checked="selectedPatientOverview.includes(key)" @change="selectPatientOverview" />
                  <label class="checked" :for="key"></label>
                </div>
              </ion-col>
              <ion-col size="3">
                <label class="checked" :for="key"
                  >{{ key }}&nbsp;&nbsp; <ion-badge v-if="count !== 'no-number'" slot="end">{{ count }}</ion-badge></label
                >
              </ion-col>
            </ion-row>
          </li>
        </ul>
        <h5 class="text-bold">Medical History</h5>
      </div>
      <div v-else class="margin-bottom-twenty">
        <p class="text-bold">
          Download this form to ensure all active chronic conditions are addressed with the patient.
        </p>
        <p>
          Review all diagnosis considerations and relevant medical history. If the condition is still present, address clinically during the visit, document in the encounter note, and submit the corresponding diagnosis code(s) on the claim.
        </p>
        <p><span class="text-bold">Print Configuration Instructions: </span> Select which sections and time frame to print. The labels show the number of records that will print per section.</p>
      </div>
      <div v-if="isLoading" class="text-center pad-ten full-height">
        <div class="centerItems text-muted display-flex valign full-height full-width">
          <ion-spinner name="lines" class="spinner-large valign"></ion-spinner>
        </div>
      </div>
      <div v-else class="margin-bottom-thirty">
        <ion-row>
          <ion-col size="3"></ion-col>
          <ion-col class="text-bold text-center col-header">Complete History</ion-col>
          <ion-col class="text-bold text-center col-header">Year-to-date</ion-col>
          <ion-col class="text-bold text-center col-header">Rolling 12 Months</ion-col>
          <ion-col class="text-bold text-center col-header"
            >Since <br />
            <div class="prime-v-date-picker-container">
              <v-date-picker
                v-model="sinceDate"
                :input-debounce="500"
                :popover="{
                  visibility: 'click'
                }"
                :input-props="{
                  class: 'form-control'
                }"
                class="prime-v-date-picker"
                @dayclick="updateSinceValues"
                @input="updateSinceValues"
              />
              <ion-icon name="md-calendar" id="dob-icon"></ion-icon>
            </div>
          </ion-col>
          <ion-col class="text-bold text-center col-header">Since Last Accountable Visit {{ lastAccountableVisit | moment("M/DD/YYYY") || "--" }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="3">
            <button v-if="Object.keys(forma).length" class="control-all" @click="deselectAll">Deselect All</button>
            <button v-else class="control-all" @click="selectAll(-1)">Select All</button>
          </ion-col>
          <ion-col v-for="(_, index) of 5" class="text-center" :key="index">
            <button class="control-all" @click="selectAll(index)" :class="index == 4 && !lastAccountableVisit ? 'invisible' : ''">Select For All</button>
          </ion-col>
        </ion-row>
        <ion-row v-for="(cat, i) in categories" :key="i">
          <ion-col size="auto" class="pad-top-ten" v-if="nonSegmentable(cat)">
            <div class="checkbox" :key="JSON.stringify(forma[cat])">
              <input :id="`${cat}_set`" class="categories" type="checkbox" color="primary" :checked="forma[cat] ? true : false" @change="checkCategory(cat, $event)" />
              <label class="checked" :for="`${cat}_set`"></label>
            </div>
          </ion-col>
          <ion-col size="3" class="text-bold pad-top-ten" v-if="nonSegmentable(cat)"
            ><label class="checked categorySet" :for="`${cat}_set`">
              {{ cat }}
              <ion-badge class="aligned-tag" v-if="cat.toLowerCase() === 'reported conditions' && responseCategories[cat] && responseCategories[cat]['Complete History'] !== null" slot="end"> {{ responseCategories[cat]["Complete History"] }} </ion-badge>
            </label>
          </ion-col>
          <ion-col size="8" v-if="nonSegmentable(cat)">
            <ion-segment class="unfillable" v-if="cat.toLowerCase() === 'reported conditions'" />
            <ion-segment v-else :key="formaUpdate" :value="hasDeselectedAll ? null : `${cat}_${columns[2]}`">
              <ion-segment-button :disabled="i == 4 && !lastAccountableVisit" :id="i + cat + '-segment'" v-for="(col, i) of columns" :key="cat + i" :value="`${cat}_${columns[i]}`" @click="checkRadio(cat, columns[i])">
                <ion-label class="text-bold " v-if="responseCategories && responseCategories[cat]">{{ responseCategories[cat][col] || 0 }} </ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-col>
          <ion-col size="12" v-if="Object.keys(subCategories).includes(cat)">
            <ion-row class="margin-top-five">
              <ion-col size="12">
                <div class="display-inline-block" style="max-width: 100%">
                  <div class="checkbox margin-left-twenty full-width">
                    <input :id="subCategories[cat]" class="sub-categories" type="checkbox" color="primary" @change="updateFilter" />
                    <label class="checked" :for="subCategories[cat]"></label>
                  </div>
                </div>
                <div class="display-inline-block margin-left-five text-bold" style="vertical-align: sub;">
                  <label class="checked" :for="subCategories[cat]">{{ subCategories[cat] }}</label>
                </div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </div>
    </ion-content>
    <ion-footer>
      <ion-grid>
        <ion-row>
          <ion-col class="pad-right-twenty full-height overflow-y-scroll">
            <button @click="closeModal()" class="prime-button button-block button-secondary">
              <span>Cancel</span>
            </button>
          </ion-col>
          <ion-col>
            <button @click="generatePdf" class="prime-button button-block" :class="{ 'button-pending': isPending }">
              <span>Generate and Download PDF </span>
              <ion-spinner name="dots" duration="1200" />
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import moment from "moment";
import { send as httpSend } from "@/services/Api";
import store from "@/store";
import IconPrint from "@/components/Global/Icons/IconPrint";

export default {
  name: "ModalPatientPDF",
  components: { IconPrint },
  props: {
    pdfType: {
      type: String,
      required: true
    },
    beneficiaryId: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      beneId: null,
      erredRecordsNumber: 0,
      isLoading: false,
      isPending: false,
      sinceDate: undefined,
      category: "",
      categories: ["Reported Conditions", "Medication History", "Lab and Result History", "Diagnosis History", "Imaging and Procedures", "Services", "Screening and Prevention"],
      subCategories: { "Diagnosis History": "Only RAPS Eligible Diagnosis" },
      responseCategories: {},
      forma: {},
      formaUpdate: 0,
      columns: ["Complete History", "Year-to-date", "Rolling 12 Months", "Since", "Since Last Accountable Visit"],
      hasDeselectedAll: false,
      lastAccountableVisit: null,
      counts: {},
      selectedPatientOverview: [],
      subCategoriesVals: null,
      sinceOnlyRAPS: 0,
      sinceValues: null,
      originalDiagnosis: null,
      selectedSubcategories: []
    };
  },
  async beforeMount() {
    this.isLoading = true;
    this.beneId = this.beneficiaryId || store.state.chatProfessional.currentPatient.beneficiary.id;
    //this.beneId = "89864";
    this.sinceDate = new Date(new Date().getFullYear(), 0, 1);
    const defaultDate = this.sinceDate.toISOString().split("T")[0];

    const results = await Promise.allSettled([await this.getPCVGuides(), await this.getPCVGuides(defaultDate)]);
    const { categories } = results[0].value;
    this.lastAccountableVisit = results[0].value["last Accountable Visit"];
    this.originalDiagnosis = { ...categories["Diagnosis History"] };
    this.subCategoriesVals = results[0].value["subCategories"];
    this.sinceOnlyRAPS = results[1].value["subCategories"]["Only RAPS Eligible Diagnosis"];
    this.sinceValues = results[1].value["categories"];
    this.counts = results[0].value["counts"] || {};
    /* part of 1199 story */
    /* this.counts = {
      "Patient Banner": "no-number",
      "Additional Patient Details": "no-number",
      "Open Quality Considerations": 6,
      "Open Diagnosis Considerations": 0,
      "Risk Scores": "no-number",
      "Reported Conditions": 0
    }; */
    this.selectedPatientOverview = Object.keys(this.counts);

    for (let key in categories) {
      categories[key].Since = this.sinceValues[key];
    }
    this.responseCategories = categories;
  },
  async mounted() {
    for (let cat of this.categories) {
      this.forma[cat] = "Rolling 12 Months";
    }
    this.isLoading = false;
  },
  methods: {
    nonSegmentable(cat) {
      return (cat.toLowerCase() !== "reported conditions" && this.pdfType === "overview PDF") || this.pdfType.toLocaleLowerCase() == "pcv guide";
    },
    updateFilter(e) {
      if (e.target.checked) {
        Object.keys(this.responseCategories["Diagnosis History"]).forEach(timeframe => {
          this.$set(this.responseCategories["Diagnosis History"], timeframe, this.subCategoriesVals["Only RAPS Eligible Diagnosis"][timeframe]);
        });
        this.$set(this.responseCategories["Diagnosis History"], "Since", this.sinceOnlyRAPS);
        this.selectedSubcategories.push(e.target.id);
      } else {
        Object.keys(this.responseCategories["Diagnosis History"]).forEach(timeframe => {
          this.$set(this.responseCategories["Diagnosis History"], timeframe, this.originalDiagnosis[timeframe]);
        });
        this.$set(this.responseCategories["Diagnosis History"], "Since", this.sinceValues["Diagnosis History"]);
        this.selectedSubcategories.splice(this.selectedSubcategories.indexOf(e.target.id));
      }
    },
    async updateSinceValues(e) {
      let selectedDate = e;
      if (!selectedDate.id) {
        selectedDate = moment(e).format("YYYY-MM-DD");
      }
      const resp = await this.getPCVGuides(selectedDate);
      this.sinceValues = resp.categories;
      this.sinceOnlyRAPS = resp.subCategories["Only RAPS Eligible Diagnosis"];
      for (let key in this.responseCategories) {
        this.$set(this.responseCategories[key], "Since", this.sinceValues[key]);
      }
      if (this.selectedSubcategories.length > 0) {
        this.$set(this.responseCategories["Diagnosis History"], "Since", this.sinceValues["Diagnosis History"]);
      }
    },
    async getPCVGuides(sinceDate) {
      const isPatientOverview = this.pdfType === "overview PDF" ? "/patient-overview" : "";
      const filters = sinceDate ? `/filter?sincedate=${sinceDate}` : "";
      const method = "get";
      const path = `${document.config.pcvGuides}/${this.beneId}${isPatientOverview}${filters}`;
      const { data } = await httpSend({ path, method, authToken: true });
      return data;
    },
    async generatePdf() {
      this.isPending = true;
      for (let key in this.forma) {
        if (this.forma[key].includes("Since") && !this.forma[key].toLowerCase().includes("since last")) {
          this.$set(this.forma, key, "Since " + moment(this.sinceDate).format("MM/DD/YYYY"));
        }
      }

      const method = "post";
      let path, payload;
      const d = new Date();
      const generatedPdfTime = new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
      if (this.pdfType === "overview PDF") {
        path = document.config.patientOverviewPdf + this.beneId;
        payload = { ...this.forma, generatedPdfTime, subCategories: this.selectedSubcategories, patientOptions: this.selectedPatientOverview };
      } else {
        path = document.config.pcvGuides + "/" + this.beneId;
        payload = { ...this.forma, generatedPdfTime, subCategories: this.selectedSubcategories };
      }
      try {
        const res = await httpSend({ path, method, payload, authToken: true });
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        const {
          data: { fileName, fileUrl }
        } = res;
        this.isPending = false;
        await this.downloadFile(fileName, fileUrl);
      } catch (error) {
        this.isPending = false;
        this.$ionic.toastController
          .create({
            header: "Failed to download file properly. Please try again later.",
            message: error,
            duration: 7000,
            position: "top"
          })
          .then(m => m.present());
        khanSolo.log(error);
      }
    },
    checkCategory(cat, e) {
      if (e.target.checked && cat.toLowerCase() !== "reported conditions") {
        const defaultPeriod = document.getElementById(`2${cat}-segment`);
        defaultPeriod.click();
        defaultPeriod.classList.add("segment-button-checked");
        this.$set(this.forma, cat, "Rolling 12 Months");
      } else {
        this.$delete(this.forma, cat);
        const activatedSegments = document.querySelectorAll(`[id*='${cat}-segment']`);
        activatedSegments.forEach(aseg => {
          aseg.classList.remove("segment-button-checked");
        });
      }
    },
    checkRadio(cat, colTimeline) {
      this.forma = Object.assign({}, this.forma, { [cat]: colTimeline });
    },
    deselectAll() {
      this.forma = {};
      this.hasDeselectedAll = true;
      const segments = document.querySelectorAll("ion-segment-button");
      segments.forEach(s => s.classList.remove("segment-button-checked", "activated"));
      this.formaUpdate = Math.random();
    },
    selectAll(colNum) {
      if (colNum == -1) {
        colNum = 2;
      }
      for (let cat of this.categories) {
        this.checkRadio(cat, this.columns[colNum]);
        if (cat.toLowerCase() === "reported conditions") continue;
        const segmentSelect = document.getElementById(`${colNum}${cat}-segment`);
        segmentSelect.click();
      }
    },
    selectPatientOverview(e) {
      const selectedPO = e.target.id;
      if (e.target.checked) {
        if (!this.selectedPatientOverview.includes(selectedPO)) this.selectedPatientOverview.push(selectedPO);
      } else {
        this.selectedPatientOverview = this.selectedPatientOverview.filter(item => item !== selectedPO);
      }
    },
    async downloadFile(fileName, fileUrl) {
      const headers = {
        "Cache-Control": "no-cache"
      };
      const responseType = "blob";
      try {
        const res = await httpSend({ path: fileUrl, method: "get", headers, authToken: false, responseType });
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.$ionic.toastController
          .create({
            header: "Failed to download file properly. Please try again later.",
            message: error,
            duration: 7000,
            position: "top"
          })
          .then(m => m.present());
        khanSolo.log(error);
      }
    },
    closeModal() {
      this.$ionic.modalController.dismiss();
    }
  }
};
</script>

<style lang="scss" scoped>
.prime-modal-content {
  border: none;
  padding: 0 20px 5px;
}

h5 {
  text-transform: capitalize;
}

ion-footer {
  padding: 20px;
}

label.categorySet {
  margin: 5px;
  display: inline-block;
}

ion-segment {
  min-height: 30px;
  width: 680px;
  background: var(--ion-color-button-bg-primary);
  border: 1px solid var(--ion-color-button-bg-primary);
  border-radius: 30px;
  margin: 5px 0 0;
}

ion-segment-button {
  min-height: 10px;
}
ion-segment-button ion-label {
  margin: 0;
  color: var(--ion-color-primary);
}

ion-segment {
  .segment-button-checked {
    background: var(--ion-color-primary);
    border: 2px solid var(--ion-color-primary);
    border-radius: 20px;
    ion-label {
      color: var(--ion-color-white);
    }
  }
}

.control-all {
  background: var(--ion-color-button-bg-primary);
  color: var(--ion-color-primary);
  font-weight: bold;
  padding: 4px 8px;
  margin: 10px;
  font-size: 10px;
  border: 1px solid var(--ion-color-button-bg-primary);
  border-radius: 10px;
}
.col-header {
  font-size: 11px;
}

label {
  font-size: 12px;
}

.counts {
  padding: 0;
  margin: 0 0 30px 0;
  list-style-type: none;
  li {
    margin: 5px 0;
    ion-row {
      align-items: baseline;
    }
    label {
      font-weight: bold;
      &:last-child {
        display: flex;
        margin-top: -2px;
      }
    }
  }
}
ion-badge {
  min-width: 23px;
  height: 16px;
  --padding-top: 1px;
}

div.checkbox:has(.discheck) {
  label {
    &::before {
      background: var(--ion-color-white-contrast);
      border: 2px solid var(--ion-color-white-contrast);
    }
  }
}

.aligned-tag {
  margin-top: -5px;
  vertical-align: middle;
}
</style>
